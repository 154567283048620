@font-face {
  font-family: CTUTechnika;
  font-style: normal;
  font-weight: normal;
  src: local('Technika'), url('../font/Technika-Regular.ttf');
}

@font-face {
  font-family: CTUTechnika;
  font-style: normal;
  font-weight: bold;
  src: local('Technika Bold'), url('../font/Technika-Bold.ttf');
}

$backgroundColor: #ffffff;
$bodyColor: #000000;
$bodyFont: CTUTechnika,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

$lightGreyColor: #ebebeb;
$lightGreyColor2: #e1e1e1;
$darkGreyColor: #666666;
$darkGreyColor2: #9b9b9b;
$ctublue: #0066bd;
$lightblue: #6aade4;
$orange: #f0ab00;

html {
  scroll-behavior: smooth;
}

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
  text-align: center;
  font-size: 0.8rem;
}

h1 {
    font-size: 2.5em;
}

h3 {
    font-size: 1.75em;
}

body * {
  margin-left: auto;
  margin-right: auto;
}

header {
  text-align: center;
}

#twitter-feed-section {
  max-width: none;
}

#twitter-feed {
  max-width: 30em;
  margin: 0 auto;
}

.nav-item {
  font-weight: bold;
  text-transform: uppercase;
}

.modal-header {
  width: 100%;
  border-bottom: none;
}

.modal-title {
    color: $ctublue;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5em;
}

.modal-body {
    color: $darkGreyColor;
}

.modal-body h5 {
    font-weight: bold;
    color: $ctublue;
    font-size: 1.25em;
    margin-top: 1em;
}

.modal-body h6 {
    color: $ctublue;
    font-size: 1.1em;
}

.panel-image-div {
  max-width: 220px;
}

#knmap {
  border: none;
  width: 800px;
  height: 400px;
}

.person-card {
    max-width: 250px;
    border: none;
}

.person-card-image {
    height: 200px;
    object-fit: contain;
    border-radius: 0px;
}

.close-tick {
    width: 1em;
    height: 1em;
    margin-top: 1.25em;
}

#header {
    background-color: $lightGreyColor;
}

#navigation {
    background-color: $darkGreyColor;
    color: white;
}

#navigation a {
    color: white;
}

#whatwedo {
     color: $darkGreyColor;
     border: solid 2px $darkGreyColor;
     border-radius: 5px;
}

#whatwedo h3 {
     text-transform: uppercase;
     font-weight: bold;
}

.panel-group-title {
    text-transform: uppercase;
    color: black;
    background-color: $lightGreyColor2;
}

.panel-group-card {
    background-color: white;
    border: none;
}

#contact-bg {
    background-color: $lightGreyColor;
}

.panel-title {
    width: 100%;
    background-color: $darkGreyColor2;
    color: white;
    border-radius: 3px;
}

.panel-image-div:hover {
    cursor: pointer;
}

.panel-image-div:hover .panel-title {
    color: $lightblue;
    background-color: $darkGreyColor;
}

.panel-image-div:hover .panel-image-img {
    background-color: $lightblue;
}

.panel-title-text {
    margin: 0;
    font-size: 1.1em;
}

#labname {
    color: $ctublue;
    font-weight: bold;
}

.light-blue {
    color: $lightblue;
}

.panel-group-head-text {
    font-weight: bold;
    margin:0;
}

#twitter-logo {
    max-width: 1.25em;
}

.item-paper {
    border: none;
}

a:hover {
    color: $lightblue !important;
}

.acknowledgements {
    color: $darkGreyColor;
    font-size: smaller;
    text-align: right;
}

.acknowledgements a {
    color: $darkGreyColor;
}

.logo {
    max-width:200px;
}


.cs-logo {
    max-width:120px;
}

.scrool-to-pt{
    padding-top: 2rem !important;
}
